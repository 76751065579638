<template>
  <div>
    <div class="home" v-show="ishome">
      <div v-if="msg" id="msg" v-cloak>
        <template v-if="$route.params.id">
          {{ msg }}
        </template>
        <div v-else class="container" style="margin: 0 20px">
          <h1 style="font-size: 24px; margin-bottom: 16px">
            {{ $t('index.companyName') }}
          </h1>
          <ul style="font-size: 16px">
            <li>
              {{ $t('index.briefIntroduction') }}
            </li>

            <li style="margin-top: 10px">
              <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">{{ $t('index.beian') }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-else-if="appInfo.status === 'hidden' || appInfo.switch == 1" style="width: 80%; text-align: center; background-color: blue; word-wrap: break-word; word-break: break-all; min-height: 200rpx; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
        {{ appInfo.download_error ? appInfo.download_error : $t('index.yixiajia') }}
      </div>
      <div v-else-if="appInfo.uploadpwd && pwdTxt !== appInfo.uploadpwd" id="password" v-cloak>
        <div style="text-align: left">
          {{ $t('index.mima') }}：
          <input type="text" v-model="pwd" :placeholder="$t('index.mimaPlaceholder')" />
        </div>
        <div style="margin-top: 10px; text-align: left; padding-left: 41px; color: red">
          {{ pwdMsg ? $t('index.mimac') : '' }}
        </div>
        <button type="button" @click="tijiao" style="border: 1px solid #ccc; padding: 5px 10px; border-radius: 5px; margin-top: 10px; text-align: right">
          {{ $t('index.tijiao') }}
        </button>
      </div>

      <div class="ember-view">
        <main class="selfclear is-apps-theme" v-cloak v-if="!msg && appInfo.status !== 'hidden' && appInfo.switch == 0 && pwdTxt === appInfo.uploadpwd">
          <input id="localnav-menustate" class="localnav-menustate" type="checkbox" />

          <div id="localnav-sticky-placeholder" class="css-sticky localnav-sticking"></div>
          <nav id="localnav" class="css-sticky we-localnav localnav we-localnav--app-store localnav-sticking" data-sticky="">
            <div class="localnav-wrapper">
              <div class="localnav-background we-localnav__background"></div>
              <div class="localnav-content we-localnav__content">
                <div class="localnav-title we-localnav__title">
                  <a href="javascript:;" data-we-link-to-exclude="">
                    <span class="we-localnav__title__product" data-test-we-localnav-store-title="">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.appstore') }}</font>
                      </font>
                    </span>
                  </a>
                </div>
                <div class="localnav-menu we-localnav__menu we-localnav__menu--apps">
                  <div class="localnav-actions we-localnav__actions"></div>
                </div>
              </div>
            </div>
          </nav>
          <span class="we-localnav__shim" aria-hidden="true"></span>
          <label id="localnav-curtain" for="localnav-menustate"></label>

          <div class="animation-wrapper is-visible">
            <section class="l-content-width section section--hero product-hero">
              <div class="l-row">
                <div class="product-hero__media l-column small-5 medium-4 large-3 small-valign-top">
                  <picture data-test-we-artwork="" class="we-artwork we-artwork--downloaded product-hero__artwork we-artwork--fullwidth we-artwork--ios-app-icon" style="--background-color: #ffe577" id="ember3">
                    <img class="ember3" :src="appInfo && appInfo.image" decoding="async" alt="" style="border-radius: 22.5%" :class="[pcFlag ? 'w230' : 'we-artwork__image']" />
                  </picture>
                </div>

                <div class="l-column small-7 medium-8 large-9 small-valign-top">
                  <header class="product-header app-header product-header--padded-start">
                    <h1 class="product-header__title app-header__title">
                      <font style="vertical-align: inherit; overflow-wrap: break-word">
                        <font style="vertical-align: inherit">{{ appInfo.ipa_name }}</font>
                      </font>
                    </h1>
                    <h3 class="app-header__designed-for">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ appInfo.mimetype }} {{ $t('index.yy') }}</font>
                      </font>
                    </h3>

                    <ul class="product-header__list app-header__list"></ul>

                    <div class="installBox" style="position: static" v-if="!pcFlag">
                      <a v-if="!downloadFlag" class="down" href="javascript:;" @click="installApp(false)">{{ $t('index.install') }}</a>
                      <span v-else>{{ $t('index.xzz') }}</span>
                    </div>
                  </header>

                  <div v-if="pcFlag" style="width: 150px; height: 100px; margin-top: 10px; float: right; text-align: center">
                    <div style="position: relative; width: 100px; height: 100px" v-if="withIconQrcode">
                      <img :src="withIconQrcode" alt="" style="width: 100px; height: 100px" />
                    </div>
                    <div style="position: relative; width: 100px; height: 100px" id="qrcodeDomId" v-else>
                      <img :src="qrcode" alt="" style="width: 100px; height: 100px" />
                      <img :src="appInfo.image" alt="" style="width: 26px; height: 26px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 99" />
                    </div>
                    <div style="margin-top: 0px; width: 100px">{{ $t('index.xiazai') }}</div>
                  </div>
                </div>
              </div>
            </section>

            <div class="comment l-content-width" v-if="!pcFlag" style="width: 87.5%">
              <strong class="publicTitle2">{{ $t('index.comment') }}</strong>
              <div class="left">
                <b>5.0</b>
                <p>{{ $t('index.points') }}</p>
              </div>
              <div class="right">
                <div class="star_row">
                  <span class="s1"><i></i></span>
                  <div class="lineBox"><var class="v1"></var></div>
                </div>
                <div class="star_row">
                  <span class="s2"><i></i></span>
                  <div class="lineBox"><var class="v2"></var></div>
                </div>
                <div class="star_row">
                  <span class="s3"><i></i></span>
                  <div class="lineBox"><var class="v3"></var></div>
                </div>
                <div class="star_row">
                  <span class="s4"><i></i></span>
                  <div class="lineBox"><var class="v4"></var></div>
                </div>
                <div class="star_row">
                  <span class="s5"><i></i></span>
                  <div class="lineBox"><var class="v5"></var></div>
                </div>
                <p>19k {{ $t('index.Score') }}</p>
              </div>
            </div>

            <!-- PC样式 -->
            <section class="l-content-width section section--bordered section--information" v-if="pcFlag">
              <div>
                <h2 class="section__headline">
                  <font style="vertical-align: inherit">
                    <font style="vertical-align: inherit">{{ $t('index.info') }}</font>
                  </font>
                </h2>
                <dl class="information-list information-list--app medium-columns l-row">
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.size') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ appInfo.filesize }}</font>
                      </font>
                    </dd>
                  </div>

                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.updateTime') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ appInfo.updatetime }}</font>
                      </font>
                    </dd>
                  </div>
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.yuyan') }}</font>
                      </font>
                    </dt>
                    <dd class="we-truncate we-truncate--multi-line we-truncate--interactive information-list__item__definition">
                      <div data-clamp="" class="we-clamp">
                        <p dir="ltr" data-test-bidi="">
                          <font style="vertical-align: inherit">
                            <font style="vertical-align: inherit">{{ $t('index.yytext') }}</font>
                          </font>
                        </p>
                      </div>
                    </dd>
                  </div>
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.age') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.agetext') }}</font>
                      </font>
                    </dd>
                  </div>

                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">Copyright</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition information-list__item__definition--copyright">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ appInfo.copyright }}</font>
                      </font>
                    </dd>
                  </div>
                  <div class="information-list__item l-column small-12 medium-6 large-4 small-valign-top">
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.jiage') }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{ $t('index.free') }}</font>
                      </font>
                    </dd>
                  </div>
                </dl>
              </div>
            </section>
            <section class="l-content-width section section--bordered section--information" v-if="pcAdShow && pcFlag && pcAd.status === 'show' && whiteList.indexOf(appInfo.user_id) === -1 && appInfo.point <= 0">
              <div style="margin: 0 auto; max-width: 80%; display: flex; justify-content: center; position: relative; cursor: pointer" @click="jumpUrl(pcAd.url)">
                <img :src="pcAd.image.includes('https://') ? pcAd.image : pcAd.url + pcAd.image" alt="" srcset="" style="max-width: 100%; height: auto" />
                <div class="topright" @click.stop="pcAdShow = false">x</div>
              </div>
            </section>
            <section v-if="appInfo.preview && appInfo.preview.length > 0" class="l-content-width section section--bordered">
              <h2 class="section__headline">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit">iPhone {{ $t('index.jt') }}</font>
                </font>
              </h2>
              <div class="we-screenshot-viewer">
                <div class="we-screenshot-viewer__screenshots">
                  <ul class="l-row l-row--peek we-screenshot-viewer__screenshots-list">
                    <li v-for="(item, index) in appInfo.preview" :key="index" class="l-column small-2 medium-3 large-3">
                      <img class="we-artwork__image ember23" :src="item" decoding="async" alt="" height="649" width="300" />
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section class="l-content-width section section--bordered" v-if="appInfo.logmassage">
              <h2 class="section__headline">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit">{{ $t('index.Updatecontent') }}</font>
                </font>
              </h2>
              <div class="we-screenshot-viewer">
                <p>{{ appInfo.logmassage }}</p>
              </div>
            </section>
            <section class="l-content-width section section--bordered" v-if="appInfo.fileinfo">
              <h2 class="section__headline">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit">{{ $t('index.Applicationintroduction') }}</font>
                </font>
              </h2>
              <div class="we-screenshot-viewer">
                <p>{{ appInfo.fileinfo }}</p>
              </div>
            </section>

            <!-- 移动端样式 -->
            <div class="appInfo" v-if="!pcFlag" style="width: 87.5% !important; margin: 0 auto">
              <strong class="publicTitle">{{ $t('index.info') }}</strong>
              <div class="box">
                <ul>
                  <li>
                    <span>{{ $t('index.size') }}</span>
                    <p>{{ appInfo.filesize }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.updateTime') }}</span>
                    <p>{{ appInfo.updatetime }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.yuyan') }}</span>
                    <p>{{ $t('index.yytext') }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.age') }}</span>
                    <p>{{ $t('index.agetext') }}</p>
                  </li>
                  <li>
                    <span>Copyright</span>
                    <p>{{ appInfo.copyright }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.jiage') }}</span>
                    <p>{{ $t('index.free') }}</p>
                  </li>
                  <li>
                    <span>{{ $t('index.yszc') }}</span>
                    <p v-if="appInfo.support_url">
                      <a :href="appInfo.support_url">✋</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div style="margin: 0 auto; max-width: 90%; display: flex; justify-content: center; position: relative; cursor: pointer" @click="jumpUrl(mobileAd.url)" v-if="mobileAdShow && !pcFlag && mobileAd.status === 'show' && whiteList.indexOf(appInfo.user_id) === -1 && appInfo.point <= 0">
              <img :src="mobileAd.image.includes('https://') ? mobileAd.image : mobileAd.url + mobileAd.image" alt="" srcset="" style="max-width: 100%; height: auto" />
              <div class="topright" @click.stop="mobileAdShow = false">x</div>
            </div>

            <div class="appInfo" v-if="appInfo && appInfo.isadvertisingisaon_status == 1">
              <a :href="appInfo && appInfo.isadvertisingisaon && appInfo.isadvertisingisaon.url">
                <img :src="appInfo && appInfo.isadvertisingisaon && appInfo.isadvertisingisaon.image" style="width: 100%" alt="" srcset="" />
              </a>
            </div>
          </div>
        </main>
      </div>

      <div class="footer" v-if="!msg && appInfo.status !== 'hidden' && appInfo.switch == 0 && pwdTxt === appInfo.uploadpwd">
        <p>
          {{ $t('index.disclaimer') }}：{{ $t('index.disclaimertext') }}
          <a href="javascript:;" style="margin-left: 5px" @click="report">
            <font style="vertical-align: inherit">
              <font style="vertical-align: inherit; color: #a9a9a9">{{ $t('index.djjb') }}</font>
            </font>
          </a>
        </p>
      </div>

      <mu-dialog title="" width="95%" :open.sync="reportFlag">
        <form onsubmit="return false;">
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.name') }}：</label>
            <input v-model="username" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.IDnumber') }}：</label>
            <input v-model="sf" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.content') }}：</label>
            <textarea v-model="title" rows="4"></textarea>
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.phonenumber') }}：</label>
            <input v-model="mobile" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.identifyingcode') }}：</label>
            <input v-model="cache" type="text" placeholder="" style="width: 80px" />
            <button class="ui-btn" @click="sendSms" style="margin-top: 6px; margin-left: 5px">
              {{ $t('index.Verification') }}
            </button>
          </div>
        </form>

        <mu-button slot="actions" color="primary" @click="closeReport">{{ $t('index.qx') }}</mu-button>
        <mu-button slot="actions" color="primary" @click="saveReport">{{ $t('index.tijiao') }}</mu-button>
      </mu-dialog>
    </div>
    <hongbao v-show="!ishome" @back="toggleBack" :appInfo="appInfo" :hongbaoAd="hongbaoAd" :whiteList="whiteList" :jumpObject="jumpObject" />
    <div class="modalClass" v-show="modalShow">
      <div style="margin-top: 25px">{{ $t('index.plaeseComputed') }}：</div>
      <div style="margin-top: 25px; width: 100%; text-align: center; font-size: 16px; color: #333">
        <span>{{ validateInfo.num1 }}</span>
        <span style="margin: 0 15px">+</span>
        <span>{{ validateInfo.num2 }}</span>
        <span style="margin-left: 15px">= ？</span>
      </div>
      <input v-model="result" type="number" :placeholder="$t('index.inputResult')" style="margin-top: 25px; height: 30px" />
      <div style="margin: 25px auto" class="confirm" @click="confirm">
        {{ $t('index.confirm') }}
      </div>
      <div class="close" @click="modalShow = false">×</div>
    </div>
  </div>
</template>

<script>
import { mixin } from '../../mixins/mixin.js';
import hongbao from './hongbao';
export default {
  mixins: [mixin],
  data() {
    return {
      home: 1,
    };
  },
  components: {
    hongbao,
  },
  name: 'Home',
};
</script>

<style scoped lang="less">
.modalClass {
  background: #fff;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%);
  width: 200px;
  // height: 300px;
  border-radius: 18px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #666;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.confirm {
  // width: 68px;
  padding: 0 25px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #1890ff;
  font-size: 14px;
  border-radius: 16px;
}
@import url(@/assets/theme.less);
</style>
