<template>
  <div style="width: 100%; height: 100%">
    <home1 v-if="showHome1 == 1" @toggleComponent="flag => (showHome1 = flag)"></home1>
    <home2 v-if="showHome1 == 2" @toggleComponent="flag => (showHome1 = flag)"></home2>
    <home3 v-if="showHome1 == 3" @toggleComponent="flag => (showHome1 = flag)"></home3>
    <home4 v-if="showHome1 == 4" @toggleComponent="flag => (showHome1 = flag)"></home4>
  </div>
</template>
<script>
import home1 from './components/homeTemplate/home1.vue';
import home2 from './components/homeTemplate/home2.vue';
import home3 from './components/homeTemplate/home3.vue';
import home4 from './components/homeTemplate/home4.vue';

export default {
  components: {
    home1,
    home2,
    home3,
    home4,
  },
  data() {
    return {
      showHome1: 1,
    };
  },
};
</script>
