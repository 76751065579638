<template>
  <div style="width: 100%">
    <div v-show="ishome" style="width: 100%">
      <!-- PC样式  pcFlag-->
      <div class="topBg" v-if="pcFlag">
        <div class="centerView">
          <div class="left">
            <img :src="appInfo.image" alt="" style="width: 120px; height: 120px; border-radius: 20px" />
            <div class="name">{{ appInfo.ipa_name }}</div>
            <div class="desc">{{ $t('index.version_no') }} {{ appInfo.xlversion }}</div>
            <div class="desc">{{ $t('index.size') }} {{ appInfo.filesize }}</div>
            <div class="desc">{{ $t('index.updateTime') }} {{ appInfo.updatetime }}</div>
          </div>
          <div class="right">
            <div @click="installApp(false)" class="installBtn">
              <img v-if="!downloaded" src="@/assets/download.png" alt="" style="width: 20px; height: 20px; margin-right: 10px" />
              <span v-if="downloaded" style="font-size: 18px">{{ $t('index.back_desktop') }}</span>
              <span v-else style="font-size: 18px">{{ downloadFlag ? $t('index.installing') : $t('index.install') }}</span>
            </div>
            <span style="color: #666" v-if="appInfo.mimetype == 'Android'">{{ $t('temp3.fitandriod') }}</span>
            <span style="color: #666" v-else>{{ $t('temp3.fitios') }}</span>
            <div style="position: relative; width: 120px; height: 120px; margin-top: 30px">
              <img :src="qrcode" style="width: 120px; height: 120px" />
              <img :src="appInfo.image" alt="" style="border-radius: 5px; width: 23px; height: 23px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 99" />
            </div>
            <span style="color: #666; margin-top: 10px">{{ $t('index.scan_download') }}</span>
          </div>
        </div>
      </div>
      <div style="width: 100%" v-else>
        <div class="topBgMobile">
          <div style="width: 100%; display: flex; flex-direction: column; align-items: center">
            <img :src="appInfo.image" alt="" style="width: 70px; height: 70px; border-radius: 10px" />
            <div style="margin: 10px 0 20px; color: #fff; font-size: 13px">{{ appInfo.ipa_name }}</div>
          </div>
        </div>
        <div style="width: 100%; display: flex; flex-direction: column; align-items: center; color: #999; margin-top: 20px">
          <div style="font-size: 15px">
            <span>{{ $t('index.version_no') }} {{ appInfo.xlversion }}</span>
            <span style="margin-left: 15px">{{ $t('index.size') }} {{ appInfo.filesize }}</span>
          </div>
          <div style="margin: 6px 0; font-size: 15px">{{ $t('index.updateTime') }} {{ appInfo.updatetime }}</div>
          <div class="installBtnDisabled" style="border-radius: 50px; padding: 8px 20px; margin-bottom: 10px" v-if="cannotInstall">
            <span style="font-size: 16px; color: #fff">{{ $t('index.no_support') }}{{ androidFlag ? $t('index.android') : ' IOS' }}</span>
          </div>
          <div @click="installApp(false)" class="installBtn" style="border-radius: 50px; padding: 8px 20px; margin-bottom: 10px" v-else>
            <img v-if="!downloaded" src="@/assets/download.png" alt="" style="width: 20px; height: 20px; margin-right: 10px" />
            <span style="font-size: 18px" v-if="downloaded">{{ $t('index.back_desktop') }}</span>
            <span style="font-size: 18px" v-else>{{ downloadFlag ? $t('index.installing') : $t('index.install') }}</span>
          </div>
          <span style="font-size: 13px" v-if="appInfo.mimetype == 'Android'">{{ $t('temp3.fitandriod') }}</span>
          <span style="font-size: 13px" v-else>{{ $t('temp3.fitios') }}</span>
        </div>
        <div style="width: 100%; padding: 0 20px; margin-top: 50px">
          <div style="border-top: 1px solid #eee; width: 100%; display: flex; flex-direction: column; padding: 30px 0">
            <span style="color: #333">{{ $t('temp3.yyms') }}</span>
            <span style="margin-top: 10px; font-size: 13px">{{ appInfo.ipa_name }}</span>
          </div>
        </div>
      </div>

      <!-- 应用名称 -->
      <div style="width: 100%; padding: 80px 150px; display: flex; flex-direction: column" v-if="pcFlag">
        <span style="font-size: 20px; color: #333">{{ $t('temp3.yyms') }}</span>
        <span style="margin-top: 10px">{{ appInfo.ipa_name }}</span>
      </div>
      <div style="position: fixed; bottom: 35px; left: 50%; transform: translateX(-50%)">
        <span style="color: #157df1; cursor: pointer" @click="dialogShow = true">{{ $t('index.disclaimer') }}</span>
        |
        <span style="color: #157df1; cursor: pointer" @click="reportFlag = true">{{ $t('index.djjb') }}</span>
      </div>
      <!-- 举报 反馈 -->
      <mu-dialog title="" width="95%" :open.sync="reportFlag">
        <form onsubmit="return false;">
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.name') }}：</label>
            <input v-model="username" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.IDnumber') }}：</label>
            <input v-model="sf" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.content') }}：</label>
            <textarea v-model="title" rows="4"></textarea>
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.phonenumber') }}：</label>
            <input v-model="mobile" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.identifyingcode') }}：</label>
            <input v-model="cache" type="text" placeholder="" style="width: 80px" />
            <button class="ui-btn" @click="sendSms" style="margin-top: 6px; margin-left: 5px">
              {{ $t('index.Verification') }}
            </button>
          </div>
        </form>

        <mu-button slot="actions" color="primary" @click="closeReport">{{ $t('index.qx') }}</mu-button>
        <mu-button slot="actions" color="primary" @click="saveReport">{{ $t('index.tijiao') }}</mu-button>
      </mu-dialog>
      <!-- 计算问题 -->
      <div class="modalClass" v-show="modalShow">
        <div style="margin-top: 25px">{{ $t('index.plaeseComputed') }}：</div>
        <div style="margin-top: 25px; width: 100%; text-align: center; font-size: 16px; color: #333">
          <span>{{ validateInfo.num1 }}</span>
          <span style="margin: 0 15px">+</span>
          <span>{{ validateInfo.num2 }}</span>
          <span style="margin-left: 15px">= ？</span>
        </div>
        <input v-model="result" type="number" :placeholder="$t('index.inputResult')" style="margin-top: 25px; height: 30px" />
        <div style="margin: 25px auto" class="confirm" @click="confirm">
          {{ $t('index.confirm') }}
        </div>
        <div class="close" @click="modalShow = false">×</div>
      </div>
      <!--  width="360"  -->
      <mu-dialog :open.sync="dialogShow" :title="$t('index.disclaimer')" @close="dialogShow = false">
        <p style="padding-top: 20px; font-size: 14px">{{ $t('temp3.disclaimer1') }}</p>
        <p style="padding-top: 20px; font-size: 14px">{{ $t('temp3.disclaimer2') }}</p>
        <p style="padding-top: 20px; font-size: 14px">{{ $t('temp3.disclaimer3') }}</p>
      </mu-dialog>
    </div>
    <!-- 下载完 红包页面 -->
    <hongbao v-show="!ishome" @back="toggleBack" :appInfo="appInfo" :hongbaoAd="hongbaoAd" :whiteList="whiteList" :jumpObject="jumpObject" />
  </div>
</template>

<script>
import { mixin } from '../../mixins/mixin.js';
import hongbao from './hongbao';
export default {
  mixins: [mixin],
  data() {
    return {
      home: 1,
      dialogShow: false,
    };
  },
  methods: {},
  components: {
    hongbao,
  },
  name: 'Home',
};
</script>

<style scoped lang="less" scope>
.topBgMobile {
  background-image: url(../../../assets/template3/temp_mobile_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-bottom: 30%;
  background-size: cover;
  padding-top: 20%;
}
.topBg {
  height: 485px;
  width: 100%;
  background-image: url(../../../assets/template3/temp_pc_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  .centerView {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1300px;
    height: 430px;
    // background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    .left {
      width: 255px;
      margin-left: 310px;
      margin-top: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        text-align: center;
        padding: 0 10px;
        width: 100%;
        font-size: 28px;
        margin-top: 24px;
        margin-bottom: 24px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .desc {
        color: #fff;
        line-height: 24px;
      }
    }
    .right {
      margin: 45px 0 0 225px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}

.installBtn {
  padding: 10px 20px;
  background-color: #157df1;
  color: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}
.installBtnDisabled {
  background-color: #ccc;
  color: #fff;
}
.modalClass {
  background: #fff;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%);
  width: 200px;
  // height: 300px;
  border-radius: 18px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #666;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.confirm {
  // width: 68px;
  padding: 0 25px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #1890ff;
  font-size: 14px;
  border-radius: 16px;
}
@import url(@/assets/theme.less);
</style>
