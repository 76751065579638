<template>
  <div style="width: 100%">
    <div v-show="ishome" style="width: 100%">
      <!-- PC样式  pcFlag-->
      <div class="topBg">
        <img :src="appInfo.image" alt="" style="width: 120px; height: 120px; border-radius: 20px" />
        <div style="margin: 10px 0; font-size: 18px; color: #222">{{ appInfo.ipa_name }}</div>
        <div style="color: #999; font-size: 13px">
          <span>{{ $t('index.version_no') }} {{ appInfo.xlversion }}</span>
        </div>
        <div style="color: #999; font-size: 13px; margin-top: 15px">
          <span>{{ $t('index.size') }} {{ appInfo.filesize }}</span>
        </div>
        <div style="color: #999; font-size: 13px; margin-top: 15px">{{ $t('index.updateTime') }} {{ appInfo.updatetime }}</div>
        <div style="display: flex; margin-top: 60px">
          <div style="display: flex; align-items: center">
            <img src="@/assets/template4/safe.png" style="width: 15px; height: 15px" alt="" />
            <span style="color: #999; font-size: 13px; margin-left: 8px">无病毒</span>
          </div>
          <div style="display: flex; align-items: center; margin-left: 15px">
            <img src="@/assets/template4/ad.png" style="width: 15px; height: 15px" alt="" />
            <span style="color: #999; font-size: 13px; margin-left: 8px">免广告</span>
          </div>
        </div>
        <div class="installBtn installBtnDisabled" v-if="cannotInstall">
          <img src="@/assets/template4/shan.png" style="width: 15px; height: 15px" alt="" />
          <span style="color: #000; margin-left: 8px">
            <b>{{ $t('index.no_support') }}{{ androidFlag ? $t('index.android') : ' IOS' }}</b>
          </span>
        </div>
        <div @click="installApp(false)" class="installBtn" v-else>
          <img v-if="!downloaded" src="@/assets/template4/shan.png" style="width: 15px; height: 15px" alt="" />
          <span style="color: #000; margin-left: 8px" v-if="downloaded">
            {{ $t('index.back_desktop') }}
          </span>
          <span style="color: #000; margin-left: 8px" v-else>
            <b>{{ downloadFlag ? $t('index.installing') : $t('temp4.install') }}</b>
          </span>
        </div>

        <div style="margin-top: 50px; padding: 30px; color: #333; font-size: 13px">
          <!-- {{ $t('index.statement') }} -->
        </div>
      </div>
      <!-- <div class="footer" v-if="!msg && appInfo.status !== 'hidden' && appInfo.switch == 0 && pwdTxt === appInfo.uploadpwd">
        <p>
          {{ $t('index.disclaimer') }}：{{ $t('index.disclaimertext') }}
          <a href="javascript:;" style="margin-left: 5px" @click="report">
            <font style="vertical-align: inherit">
              <font style="vertical-align: inherit; color: #a9a9a9">{{ $t('index.djjb') }}</font>
            </font>
          </a>
        </p>
      </div> -->
      <div style="position: fixed; bottom: 35px; left: 50%; transform: translateX(-50%)">
        <span style="color: #157df1; cursor: pointer" @click="dialogShow = true">{{ $t('index.disclaimer') }}</span>
        |
        <span style="color: #157df1; cursor: pointer" @click="reportFlag = true">{{ $t('index.djjb') }}</span>
      </div>

      <!-- 举报 反馈 -->
      <mu-dialog title="" width="95%" :open.sync="reportFlag">
        <form onsubmit="return false;">
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.name') }}：</label>
            <input v-model="username" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.IDnumber') }}：</label>
            <input v-model="sf" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.content') }}：</label>
            <textarea v-model="title" rows="4"></textarea>
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.phonenumber') }}：</label>
            <input v-model="mobile" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label>{{ $t('index.identifyingcode') }}：</label>
            <input v-model="cache" type="text" placeholder="" style="width: 80px" />
            <button class="ui-btn" @click="sendSms" style="margin-top: 6px; margin-left: 5px">
              {{ $t('index.Verification') }}
            </button>
          </div>
        </form>

        <mu-button slot="actions" color="primary" @click="closeReport">{{ $t('index.qx') }}</mu-button>
        <mu-button slot="actions" color="primary" @click="saveReport">{{ $t('index.tijiao') }}</mu-button>
      </mu-dialog>

      <!-- 计算问题 -->
      <div class="modalClass" v-show="modalShow">
        <div style="margin-top: 25px">{{ $t('index.plaeseComputed') }}：</div>
        <div style="margin-top: 25px; width: 100%; text-align: center; font-size: 16px; color: #333">
          <span>{{ validateInfo.num1 }}</span>
          <span style="margin: 0 15px">+</span>
          <span>{{ validateInfo.num2 }}</span>
          <span style="margin-left: 15px">= ？</span>
        </div>
        <input v-model="result" type="number" :placeholder="$t('index.inputResult')" style="margin-top: 25px; height: 30px" />
        <div style="margin: 25px auto" class="confirm" @click="confirm">
          {{ $t('index.confirm') }}
        </div>
        <div class="close" @click="modalShow = false">×</div>
      </div>
      <!--  width="360"  -->
      <mu-dialog :open.sync="dialogShow" :title="$t('index.disclaimer')" @close="dialogShow = false">
        <p style="padding-top: 20px; font-size: 14px">{{ $t('temp3.disclaimer1') }}</p>
        <p style="padding-top: 20px; font-size: 14px">{{ $t('temp3.disclaimer2') }}</p>
        <p style="padding-top: 20px; font-size: 14px">{{ $t('temp3.disclaimer3') }}</p>
      </mu-dialog>
    </div>
    <!-- 下载完 红包页面 -->
    <hongbao v-show="!ishome" @back="toggleBack" :appInfo="appInfo" :hongbaoAd="hongbaoAd" :whiteList="whiteList" :jumpObject="jumpObject" />
  </div>
</template>

<script>
import { mixin } from '../../mixins/mixin.js';
import hongbao from './hongbao';
export default {
  mixins: [mixin],
  data() {
    return {
      home: 1,
      dialogShow: false,
    };
  },
  methods: {},
  components: {
    hongbao,
  },
  name: 'Home',
};
</script>

<style scoped lang="less">
.topBg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../assets/template4/bg.jpg);
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.installBtn {
  background-color: #92fa99;
  width: 290px;
  height: 50px;
  border-radius: 13px;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.installBtnDisabled {
  background-color: #ccc;
  color: #fff;
}
@import url(@/assets/theme.less);
</style>
